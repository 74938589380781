<template>
  <div style="overflow-y: hidden;">
    <welcome-header
      :title="title"
      @click:back="onClickBack"
    />

    <div style="position: relative; top: 56px; height: calc(100% - 56px); overflow-y: auto;">
      <div
        v-if="! loading"
        class="listbody"
      >
        <div class="dtop">
          <span>{{ item.regDatetime | date }} | {{ '등록자' | translate }} : {{ item.register }}</span>
        </div>

        <div class="dcont">
          <div class="dcont_txt">
            <div class="notice_content">
              <div
                class="ql-container ql-snow"
                style="border: 0; width: 100%;"
              >
                <div class="ql-editor px-0">
                  <span v-html="item.content" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-else
        style="width: 100%; height: 100%;"
      >
        <progress-loading />
      </div>
    </div>
  </div>
</template>

<script>
    import WelcomeMixins from "@/views/welcome/part/WelcomeMixins";
    import WelcomeHeader from "@/views/welcome/part/WelcomeHeader";
    import {PATH} from "@/constants";
    import ProgressLoading from "@/components/part/ProgressLoading";

    export default {
        name: "WelcomeNoticeDetail",
        components: {ProgressLoading, WelcomeHeader},
        mixins: [WelcomeMixins],
        props: {
            noticeId: undefined,
        },
        data() {
            return {
                loading: false,
                item: {},
            };
        },
        computed: {
            title() {
              if (this.loading) return "  ";
              return this.item.subject;
            },
        },
        mounted() {
            this.load();
        },
        methods: {
            async load() {
                this.loading = true;

                try {
                    const response = await this.$request(PATH.PUBLIC.WELCOME.DETAIL)
                        .setObject({noticeId: this.noticeId})
                        .enqueue();
                    this.item = response;
                } catch (error) {
                    console.error(error);
                    this.$alert(this.$translate("게시물을 불러오는데 실패하였습니다!"));
                }
                this.loading = false;
            },
        },
    }
</script>

<style scoped>
    .mbody {
        position: inherit !important;
    }

    .listbody {
        text-align: left
    }

    .dtop {
        border-bottom: 1px solid #ddd;
        padding: 14px 18px;
    }

    .dtop h2 {
        font-size: 1rem;
        padding-bottom: 6px;
    }

    .dtop span {
        font-size: 0.875rem;
    }

    .dcont {
        padding: 0 18px 14px 18px;
    }

    .dcont img {
        width: 100%;
    }

    .dcont .dcont_txt {
        font-size: 1rem;
        line-height: 28px;
        letter-spacing: -0.8px;
    }

</style>