<template>
  <header>
    <button @click="$emit('click:back')">
      <img src="@/assets/images/main/svg/button__Previous.svg">
    </button>
    <div class="headtit">
      {{ title }}
    </div>
  </header>
</template>

<script>
    export default {
        name: "WelcomeHeader",
        props: {
            title: {
                type: String,
                default: "No Title",
            },
        },
    }
</script>

<style scoped>
    header {
        height: 56px;
        max-height: 56px;
        border-bottom: 1px solid #ddd;
        padding: 0 18px;
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
    }

    header button {
        display: inline-block;
        padding-right: 18px;
    }

    header .headtit {
        font-size: 1.25rem;
        line-height: 56px;
        font-weight: 700;
        display: inline-block;
    }
</style>