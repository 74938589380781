<template>
  <div style="overflow-y: hidden;">
    <welcome-header
      :title="title"
      @click:back="onClickBack"
    />

    <div style="position: relative; top: 56px; height: calc(100% - 56px); overflow-y: hidden;">
      <!-- 인피니트 리스트 -->
      <vue-infinite-loader
        :items="items"
        :fetch="fetch"
        :identifier="identifier"
        item-key="noticeId"
      >
        <template #default="{ item }">
          <div
            class="notice-list__content"
            @click="onClickWelcomeNoticeDetail(item)"
          >
            <div class="listtxt">
              {{ item.subject }}
            </div>
            <div style="font-size: .825rem;">
              {{ item.regDatetime | date }}
            </div>
          </div>
        </template>
      </vue-infinite-loader>
    </div>
  </div>
</template>

<script>
    import WelcomeMixins from "@/views/welcome/part/WelcomeMixins";
    import WelcomeHeader from "@/views/welcome/part/WelcomeHeader";
    import {NOTICE_SORT} from "@/constants/notice-value";
    import {PATH} from "@/constants";

    export default {
        name: "WelcomeNoticeList",
        components: {WelcomeHeader},
        mixins: [WelcomeMixins],
        props: {
            sortCd: undefined,
        },
        data() {
            return {
                items: [],
                searchOptions: {},
                identifier: 0,
                mounted: false,
            };
        },
        computed: {
            title() {
                let title = " ";
                switch (this.sortCd) {
                    case NOTICE_SORT.PRODUCT_INFORMATION:
                        title = this.$translate("제품정보")
                        break;
                    case NOTICE_SORT.INDUSTRY_TRENDS :
                        title = this.$translate("업계동향")
                        break;
                }
                return title;
            },
        },
        mounted() {
            this.mounted = true;
          if (this.historyManager.isForward) { // pushState 한 경우만 초기화
            this.identifier++;
          }
        },
        methods: {
            onClickWelcomeNoticeDetail(item) {
                this.routerManager.push({
                    name: 'WelcomeNoticeDetail', params: {
                        noticeId: item.keys.noticeId
                    }
                });
            },

            async fetch(page, pageSize) {
                this.searchOptions.pageNumber = page;
                this.searchOptions.pageSize = pageSize;

                const response = await this.$request(PATH.PUBLIC.WELCOME.LIST_NOTICE)
                    .setObject({...this.searchOptions, sortCd: this.sortCd, countryCode: this.$countryAuth()})
                    .catch()
                    .wait(500)
                    .enqueue();
                return response.data;
            },
        }
    }
</script>

<style scoped>
    /*list _ start*/
    .mbody {
        position: inherit !important;
    }

    .listbody {
        text-align: left
    }


    header {
        height: 56px;
        border-bottom: 1px solid #ddd;
        padding: 0 18px
    }

    header button {
        display: inline-block;
        padding-right: 18px;
    }

    header .headtit {
        font-size: 1.25rem;
        line-height: 56px;
        font-weight: 700;
        display: inline-block;
    }

    .notice-list__content {
        list-style: none;
        border-bottom: 1px solid #ddd;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 14px 16px;
    }

    .listtxt {
        text-align: left;
        text-decoration: none;
        color: #000;
        display: inline-block;
        padding: 0;
    }

    /*list _ end*/
</style>